<template>
  <a :href="`/products/${productData.code}`" class="col-sm-12 col-lg-4 my-3">
    <div class="product-card card mb-3 bg-light shadow overflow-hidden">
      <div class="row g-0">
        <div class="col-12 text-center text-sm-end">
          <img :src="productImage" class="img-fluid w-100" loading="lazy" />
        </div>
        <div class="col-12 text-sm-end" dir="ltr">
          <div class="card-body text-start">
            <h5 class="card-title fs-4 fw-bolder d-flex align-items-center">
              {{ productData.title }}
              <span class="text-muted fw-lighter ms-auto">{{
                productData.code
              }}</span>
              <span
                class="badge text-dark fs-2 fw-bolder ms-auto"
                v-if="productData.w"
                >{{ productData.w }}
                <img src="../assets/images/watt_1@4x.png" width="40" alt="watt"
              /></span>
            </h5>
            <!-- <table class="table table-hover">
              <tbody>
                <tr class="table-secondary" v-if="productData.v">
                  <td>Voltage (V)</td>
                  <td>{{ productData.v }}</td>
                </tr>
                <tr class="table-light" v-if="productData.frequency">
                  <td>Frequency (Hz)</td>
                  <td>{{ productData.frequency }}</td>
                </tr>
                <tr class="table-secondary" v-if="productData.luminous">
                  <td>Luminous Flux (Lm)</td>
                  <td>{{ productData.luminous }}</td>
                </tr>
                <tr class="table-light" v-if="productData.colortemp">
                  <td>Color Temperature</td>
                  <td>{{ productData.colortemp }}</td>
                </tr>
                <tr class="table-secondary" v-if="productData.ip">
                  <td>IP</td>
                  <td>{{ productData.ip }}</td>
                </tr>
                <tr class="table-light" v-if="productData.pf">
                  <td>Power Factor (PF)</td>
                  <td>&#8805;{{ productData.pf }}</td>
                </tr>
                <tr class="table-secondary" v-if="productData.life">
                  <td>Life Time (H)</td>
                  <td>{{ productData.life }}</td>
                </tr>
              </tbody>
            </table> -->
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: ["productData"],
  // mounted() {
  //   const allImages = this.$store.state.imagesPath;
  //   const selectedImages = allImages.filter((e) =>
  //     e.includes(`${this.productData.code}/`)
  //   );
  //   console.log(selectedImages[0]);
  // },
  methods: {
    goToDetails() {
      window.scroll(0, 0);
      this.$router.push(`/products/${this.productData.code}`);
    }
  },

  // mounted() {
  //   // return require(`../assets/images/productsImages/${selectedImages[0]}`);
  //   console.log({
  //     code: this.productData.code,
  //     isExist: Boolean(
  //       (`../assets/images/productsImages/${this.productData.code}/1_small.jpg`)
  //     ),
  //   });
  // },
  computed: {
    productImage() {
      try {
        // const allImages = this.$store.state.imagesPath;
        // const selectedImages = allImages.filter((e) =>
        //   e.includes(`${this.productData.code}/`)
        // );
        // return require(`../assets/images/productsImages/${selectedImages[0]}`);
        return require(`../assets/images/productsImages/${this.productData.code}/1_small.jpg`);
      } catch (err) {
        return require("../assets/images/notFound.jpg" || "");
      }
    }
  }
};
</script>

<style scoped>
.img-fluid {
  height: 270px;
  /* width: max-content; */
  object-fit: contain;
}
</style>
