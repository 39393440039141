<template>
  <div>
    <header class="product-header">
      <div class="container">
        <h2>كشافات High BAY</h2>
        <!-- <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Distinctio
          numquam recusandae maxime, blanditiis, ipsa aut quibusdam voluptatibus
          obcaecati itaque dolore hic eligendi ex praesentium fugit voluptatem.
          Delectus necessitatibus sapiente consequatur!
        </p> -->
        <div class="inside-index">
          <a href="#Alpha" class="btn">Alpha</a>
          <a href="#Lamar" class="btn">Lamar</a>
        </div>
      </div>
    </header>

    <div id="Alpha" class="container" dir="ltr">
      <h2 class="display-2 text-center py-2"><u>Alpha</u></h2>
      <div class="row">
        <ProductCard
          v-for="product in alpha"
          :key="product.id"
          :productData="product"
        />
      </div>
    </div>
    <div id="Lamar" class="container" dir="ltr">
      <h2 class="text-center">
        <img
          src="@/assets/images/marks/marks-02.png"
          class="product-title-img"
          alt="lamar"
        />
      </h2>
      <div class="row">
        <ProductCard
          v-for="product in lamar"
          :key="product.id"
          :productData="product"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard";
export default {
  components: {
    ProductCard
  },
  data() {
    return {
      pData: this.$store.getters.getData
    };
  },

  computed: {
    alpha() {
      return this.pData.filter(a => a.family == "alpha" && a.type == "h");
    },
    lamar() {
      return this.pData.filter(a => a.family == "lamar" && a.type == "h");
    }
  }
};
</script>
